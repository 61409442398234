import { Component, OnInit, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-reset-btn',
  templateUrl: './reset-btn.component.html',
  styleUrls: ['./reset-btn.component.scss']
})
export class ResetBtnComponent implements OnInit {
@Output() resetfn = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

}
