import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { CommonService } from "../shared/common.service";

@Injectable({providedIn: "root"})
export class AuthGuardMain implements CanActivate {
  constructor(private router: Router, private commonService: CommonService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.commonService.isAuthenticated()) {
      let userData = this.commonService.getUser();

      if (route.data.role && route.data.role.indexOf(userData.role) == -1) {
  
        if (userData['role'] =='admin') {
          this.router.navigate(["/main/admin/dashboard"])
        }
        if (userData['role'] =='employee') {
          this.router.navigate(["/main/employee/dashboard"])
        }
        if (userData['role'] =='manager') {
          this.router.navigate(["/main/manager/dashboard"])
        }
       
        return false;
      } else {
  
        return true;
      }
    }else {
      this.router.navigate(["/signin"])
      return false;
    }
  }

}
