import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from "./common.service";
import Swal from "sweetalert2";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigurationService } from "./configuration.service";
@Injectable()
export class NetworkService {
  private backendApiURL : any
  //  = environment.backendApiURL;
  
  constructor(
    private http: HttpClient,
    private _commonService: CommonService,
    private _snackBar: MatSnackBar,
    private config: ConfigurationService
  ) {
    this.backendApiURL = this.config.readConfig().backendApiURL
  } 
    

  get(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.get(this.backendApiURL + url, this._commonService.getToken(auth)).pipe(
      catchError(this.handleError.bind(this)));
  }

  login(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.post(this.backendApiURL + url, param, {
      observe: 'response' as 'response'
    }).pipe(
      catchError(this.handleError.bind(this)));
  }

  post(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.post(this.backendApiURL + url, param, this._commonService.getToken(auth)).pipe(
      catchError(this.handleError.bind(this)));;
  }

  delete(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.delete(this.backendApiURL + url, this._commonService.getToken(auth)).pipe(
      catchError(this.handleError.bind(this)));
  }

  put(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.put(this.backendApiURL + url, param, this._commonService.getToken(auth)).pipe(
      catchError(this.handleError.bind(this)));;
  }
  uploadImages(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    let token = localStorage.getItem("token");
    let options_: any = {
      headers: new HttpHeaders({
        Authorization: token
      })
    };
    return this.http.post(this.backendApiURL + url, param, options_);
  }


  handleError(error: HttpErrorResponse) {
    if (error.status == 401) {
      this._commonService.logOut();
    } else if (error.status == 404) {
      this._snackBar.open(error.error.data.message,null, {
        duration: 4000,
        horizontalPosition:'right',
        verticalPosition:'top',
        panelClass: ['failure']
      });
      }
      else if(error.status == 400) {
        if (error.error.data.message =='"email" must be a valid email') {
          error.error.data.message ="Enter a valid email"
          this._snackBar.open(error.error.data.message,null, {
            duration: 4000,
            horizontalPosition:'right',
            verticalPosition:'top',
            panelClass: ['failure']
          });
      }else {
        this._snackBar.open(error.error.data.message,null, {
          duration: 4000,
          horizontalPosition:'right',
          verticalPosition:'top',
          panelClass: ['failure']
        });
      }
     
      //Swal.fire("Error", error.statusText, "error");
    } else {
      this._snackBar.open(error.error.data.message,null, {
        duration: 4000,
        horizontalPosition:'right',
        verticalPosition:'top',
        panelClass: ['failure']
      });
   //   Swal.fire("Error", error.error.data, "error");
    }
    return throwError(error)
  }

}
