import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-file',
  templateUrl: './no-file.html',
  styleUrls: ['./no-file.scss']
})
export class NoFile implements OnInit {
	dummyData = [1,2,3,4,5,6,7,8,9];
  constructor() { }

  ngOnInit() {
  }

}
