<div
    [class.full-screen-loader]="type === 'fullScreen'"
    [class.screen-loader]="type === 'normal'"
>
    <div class="justify-content-center spinner">
        <div class="spinner-container">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>

<!-- <div class="loader">Loading...</div> -->