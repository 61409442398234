<footer class="site-footer">
    <div class="container">
        <div class="row">
            <br/> <br/>
            <div class="col-xs-6 col-md-3">
                <h6>Quick Links</h6>
                <ul class="footer-links">
                    <li><a href="/">Home</a></li>
                    <li><a routerLink="/about">About Us</a></li>
                    <li><a href="/faq">FAQ</a></li>
                    <li><a routerLink="/login">Register</a></li>
                </ul>
            </div>
            <div class="col-xs-6 col-md-3">
                <h6>Help Desk</h6>
                <ul class="footer-links">
                    <li routerLink="/helpDesk"><a>Contact Us</a></li>
                </ul>
            </div>
            <div class="col-xs-12 col-md-6">
                <h6>Subscribe to our Newsletter</h6>
                <div class="flex-input" style="display: flex;">
                    <input placeholder="What's your email" style="    padding: 3% 5%; width: 70%;" class="input">
                    <button style="width: 30%;     background: gray;
                    color: white;">SEND</button>
                </div>
            </div>
        </div>
    </div>
    <hr>
    
    <button id="myBtn" *ngIf="isShow" (click)="gotoTop()"> <img  class="scroll_img" height="20px" width="20px" src="../../../assets/images/up-arrow.png"></button>
    <div class="container">
        <div class="row">
            <div class="" style="margin: auto;">
                <ul class="social-icons" style="text-align: center;">
                    <li class="showCursor" routerLink="/term">Terms of Use</li>
                    <li class="showCursor" routerLink="/privacyPolicy">Privacy Policy</li>
                    <li class="showCursor" routerLink="/cookiePolicy">Cookie Policy</li>
                    <li>©Maap 2020</li>
                </ul>
            </div>
        </div>
    </div>
    </footer>