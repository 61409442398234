<!-- 
  <section>
    <div class="row">
      <div  class="col-lg-4 col-md-4 mt-3" *ngFor = "let item of dummyData">
        <div class="card text-center p-3 mb-2">
            <img class="d-block image mb-2" src="../../../assets/images/no_user.png">
            <h5></h5>
            <p class="mt-1 mb-1" style="font-size:13px"></p>
            <p class="desciption_bio"  > </p>
            
            <p  class="mt-3 mb-1 type"></p>

            <button class="btn btn-primary connectBtn w-100 mt-2 mb-2" ></button>                   
        </div>
      </div>
  </div>
  </section>       -->


  <div class="row col-md-12 mt-5 mb-5 justify-content-center m-0">
    <img src="../../../assets/images/no_data.png">
  </div>