import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NetworkService } from '../shared/network.service';
@Injectable({
  providedIn: 'root'
})
export class AuthServiceLocal {
  private pageChange = new BehaviorSubject('false');
  currentPage = this.pageChange.asObservable();

  constructor(private networkService: NetworkService,
    ) { }

  Page(message: string) {
    this.pageChange.next(message)
  }
  validateLogin(req: any) {
    return this.networkService.login("api/admin/login", req, null, null);
  }
  otherLogin(req: any) {
    return this.networkService.login("api/user/login", req, null, null);
  }
  forgotPassword(req: any) {
    return this.networkService.post("api/user/forgotPassword", req, null, null);
  }
  verifyEmail(body:any) {
    return this.networkService.post("api/user/verify",body, null,null);
  }
  resetpassword(req: any) {
    return this.networkService.post("api/user/verify/password/forgot", req, null, null);
  }
  candidateAssement(Id:any) {
    return this.networkService.get("api/userassessment/candidate/list/"+Id, null, null, null);
  }
 
  submitCandidateAssessment(req: any) {
    return this.networkService.post("api/userassessment/candidate/submit", req, null, null);
  }
  uploadPdf(image: any) {
    const formData = new FormData();
    formData.append("image",image);
    return this.networkService.uploadImages("api/webview", formData, null, "bearer");
  }
  createOTP(body:any) {
    return this.networkService.post("api/otps/create",body, null,null);
  }
}
