import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { FullComponent } from '../layouts/full/full.component';
import { AppSidebarComponent } from '../layouts/full/sidebar/sidebar.component';
import { AppHeaderComponent } from '../layouts/full/header/header.component';
import { SharedModule } from '../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';



@NgModule({
  declarations: [MainComponent,FullComponent,AppSidebarComponent,AppHeaderComponent],
  imports: [
    BrowserModule,
    CommonModule,
    MainRoutingModule,
    SharedModule,
    // DemoMaterialModule
  ],
  exports:[]
  
})
export class MainModule { }
