import { Component, OnInit, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-btn',
  templateUrl: './search-btn.component.html',
  styleUrls: ['./search-btn.component.scss']
})
export class SearchBtnComponent implements OnInit {
@Output() searchfn =new EventEmitter()
  constructor() { }

  ngOnInit() {
  }

}
