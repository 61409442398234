import { Component, HostListener, OnInit } from "@angular/core";

import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CommonService } from "../../../shared/common.service";
import { AuthServiceLocal } from "../../auth.service";
import { Router } from "@angular/router";
import { HttpResponse } from "@angular/common/http";
import { StreamService } from "../../../shared/stream.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { trigger, state, style, animate, transition } from "@angular/animations";
@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"],
  animations: [
    trigger("topInAnimation", [
      transition(":enter", [
        style({ transform: "translateY(-100%)" }),
        animate("500ms", style({ transform: "translateY(0)" })),
      ]),
    ]),
  ],
})
export class SigninComponent implements OnInit {
  loginForm: FormGroup;
  resetForm: FormGroup;
  formSubmitted: boolean = false;
  isLoading: boolean = false;
  show: boolean = false;
  isLogin: boolean = true;
  emailPattern =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
  message: string = "";
  type: string = "0";
  isHover: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authServices: AuthServiceLocal,
    private commonService: CommonService,
    private streamService: StreamService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.initialiseForms();
  }
  @HostListener("mouseenter") mouseove(event: Event) {
    this.isHover = true;
  }
  @HostListener("mouseleave") mouseleave(event: Event) {
    this.isHover = false;
  }
  initialiseForms() {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      type: ["0", [Validators.required]],
    });
    this.resetForm = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
    });
  }

  validatEmail() {
    this.formSubmitted = true;
    if (this.resetForm.valid) {
      let req = {
        email: this.resetForm.controls["email"].value.toLowerCase(),
      };
      this.isLoading = true;
      this.authServices.verifyEmail(req).subscribe(
        (res) => {
          if ((res["message"] = "Success")) {
            this.resetSubmit();
          } else {
          }
        },
        (err) => {
          this._snackBar.open("Email Verified", "", {
            duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ["success"],
          });
        }
      );
    }
  }
  resetSubmit() {
    this.formSubmitted = true;
    if (this.resetForm.valid) {
      let req = {
        email: this.resetForm.controls["email"].value.toLowerCase(),
      };
      this.isLoading = true;
      this.authServices.forgotPassword(req).subscribe(
        (res) => {
          if ((res["message"] = "Success")) {
            this.isLoading = false;
            this.isLogin = true;
            this.initialiseForms();
            this._snackBar.open("Password Forgot successfully , Please check Your mail!!", "", {
              duration: 3000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["failure"],
            });
          } else {
          }
        },
        (err) => {
          this.isLoading = false;
        }
      );
    }
  }
  login() {
    this.formSubmitted = true;
    //     if (!this.loginForm.valid) {
    //   return null;
    // }
    let req = {
      email: this.loginForm.controls["email"].value.trim(),
      password: this.loginForm.controls["password"].value.trim(),
    };
    this.isLoading = true;
    this.authServices.validateLogin(req).subscribe(
      (res: HttpResponse<any>) => {
        this.isLoading = false;
        if ((res["message"] = "Success")) {
          this.commonService.setStorage("token", res.headers.get("Authorization"));

          if (res.body.data["accessToken"]) {
            this.commonService.setStorage("token", res.body.data["accessToken"]);
          }
          let userData = this.commonService.getUser();

          if (userData["role"] == "admin") {
            this.router.navigate(["/admin-dashboard"]);
          } else {
            this._snackBar.open("You Don't have an access for this portal", "", {
              duration: 3000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["success"],
            });
          }

          localStorage.setItem("role", userData["role"]);
          localStorage.setItem("activeIndex", "0");
          this.sendRouteVal(0);
        } else {
        }
      },
      (err) => {
        this._snackBar.open(err.error.data.message, "", {
          duration: 3000,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ["success"],
        });
        this.isLoading = false;
      }
    );
  }
  navigateForgot(value) {
    if (value == "true") {
      this.isLogin = true;
      this.loginForm.reset();
      this.initialiseForms();
    } else {
      this.isLogin = false;
      this.loginForm.reset();
    }
  }

  sendRouteVal(index) {
    this.streamService.streamMessage(index);
  }
  password() {
    this.show = !this.show;
  }
  createOtp() {
    let requuest = {
      email: this.loginForm.value.email,
      type: "MR",
    };

    this.authServices.createOTP(requuest).subscribe(
      (res: HttpResponse<any>) => {
        this.isLoading = false;
      },
      (err) => {
        this._snackBar.open(err.error.data.message, "", {
          duration: 3000,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ["success"],
        });
        this.isLoading = false;
      }
    );
  }
  mouseover(event) {
    console.log("123");
  }
}
