
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './auth/auth.module';
import { MainModule } from './main/main.module';
import { NetworkService } from './shared/network.service';
import { CommonService } from './shared/common.service';
import { AuthGuardMain } from './auth/auth-guard.service';
import { AppRoutingModule } from './app.routing';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ConfigurationService } from './shared/configuration.service';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';





const routes: Routes = [
  { path: "", redirectTo: "/signin", pathMatch: "full" }
];
const appInitializerFn = (configService: ConfigurationService) => {
  return () => {
    return configService.setConfig();
  };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AuthModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    MainModule,
    NgbModule,
    AppRoutingModule,
    FullCalendarModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  
  providers: [NetworkService,AuthGuardMain,CommonService, {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFn,
    multi: true,
    deps: [ConfigurationService]
  }],
  bootstrap: [AppComponent]
})
export class AppModule {}
