import { Component, OnInit, Renderer2, OnDestroy, AfterViewChecked } from '@angular/core';
import { HostListener } from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
@Component({
  selector: 'app-lg-footer',
  templateUrl: './lg-footer.component.html',
  styleUrls: ['./lg-footer.component.scss']
})
@HostListener('window:scroll', ['$event'])
export class LgFooterComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  isShow: boolean;
  topPosToStartShowing = 100;
  constructor(
    private renderer: Renderer2,
    private scrollDispatcher: ScrollDispatcher
  ) { 
    
  }

  ngOnInit() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
    window.scrollTo(0, 0);
    this.renderer.addClass(document.body, 'white-bg');
    this.renderer.addClass(document.body, 'landing-page');
  }
  @HostListener('window:scroll')
  checkScroll() {
      
    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'white-bg');
    this.renderer.removeClass(document.body, 'landing-page');
  }
  

}
