import { Injectable } from "@angular/core";
import { CommonService } from "../common.service";


export interface Menu {
  state: string;
  name: string;
  type: string;
  iconUrl: string;
  iconUrlActive:string
}



// const MENUITEMS = [
//   { state: 'dashboard', name: 'Dashboard', iconUrl:'assets/images/dashboard_white.png', type: 'link', iconUrlActive:'assets/images/dashboard_color.png'},
//   { state: 'search', name: 'Search', iconUrl:'assets/images/search_color.png', type: 'link',iconUrlActive:'assets/images/search_white.png'},
//   { state: 'message', name: 'Message', iconUrl:'assets/images/message_white.png', type: 'link',iconUrlActive:'assets/images/message_color.png'},
//   { state: 'match', name: 'My Matches', iconUrl:'assets/images/match_white.png', type: 'link',iconUrlActive:'assets/images/match_color.png'},
//   { state: 'profile', name: 'My Profile', iconUrl:'assets/images/profile_white.png', type: 'link',iconUrlActive:'assets/images/profile_color.png'},
//   { state: 'subscription', name: 'Subscription', iconUrl:'assets/images/ic_subscription.svg', type: 'link',iconUrlActive:'assets/images/ic_Subscription_fill.svg'},
//   { state: 'payment', name: 'Payment', iconUrl:'assets/images/payment_white.png', type: 'link',iconUrlActive:'assets/images/payment_color.png'},
//   { state: 'favourite', name: 'My Favorite', iconUrl:'assets/images/fav_white.png', type: 'link',iconUrlActive:'assets/images/fav_color.png'},
//   { state: 'blog', name: 'My Blogs', iconUrl:'assets/images/writing_white.png', type: 'link',iconUrlActive:'assets/images/writing_color.png'},

//      ];

const MENUITEMS = [
  { state: 'admin/dashboard', name: 'Dashboard', iconUrl:"assets/images/Dashboard_icon.svg", type: 'link', iconUrlActive:'assets/images/Dashboard_icon_white.svg'},
  { state: 'admin/all-manager', name: 'Managers', iconUrl:"assets/images/Managers_icon.svg", type: 'link', iconUrlActive:'assets/images/Managers_icon_white.svg'},
//   { state: 'admin/all-resource', name: 'Resources', iconUrl:"assets/images/Resources_icon.svg", type: 'link', iconUrlActive:'assets/images/Resources_icon_white.svg'},
//  { state: 'admin/view-assessment', name: 'Assessment', iconUrl:"assets/images/Assessment_icon.svg", type: 'link', iconUrlActive:'assets/images/Assessment_icon_white.svg'},
//   { state: 'admin/view-employee', name: 'Employees', iconUrl:"assets/images/Employees_icon.svg", type: 'link', iconUrlActive:'assets/images/Employees_icon_color.svg'},
//   { state: 'admin/view-candidate', name: 'Candidates', iconUrl:"assets/images/Candidates_icon.svg", type: 'link', iconUrlActive:'assets/images/Candidates_icon_white.svg'},
//   { state: 'admin/assessment-details', name: 'Assessment Details', iconUrl:"assets/images/Candidates_icon.svg", type: 'nolink', iconUrlActive:'assets/images/Candidates_icon_white.svg'},
];
const MENUMANAGER = [
{ state: 'manager/dashboard', name: 'Dashboard', iconUrl:"assets/images/Dashboard_icon.svg", type: 'link', iconUrlActive:'assets/images/Dashboard_icon_white.svg'},
{ state: 'manager/list-resource', name: 'Resources', iconUrl:"assets/images/Resources_icon.svg", type: 'link', iconUrlActive:'assets/images/Resources_icon_white.svg'},
{ state: 'manager/list-assessment',  name: 'Assessment',iconUrl:"assets/images/Assessment_icon.svg", type: 'link', iconUrlActive:'assets/images/Assessment_icon_white.svg' },
{ state: 'manager/list-employee', name: 'Employees', iconUrl:"assets/images/Dashboard_icon.svg", type: 'link', iconUrlActive:'assets/images/Dashboard_icon_white.svg'},
{ state: 'manager/list-assessment',  name: 'Assessment Assigned', iconUrl:"assets/images/Employees_icon.svg", type: 'Nolink', iconUrlActive:'assets/images/Assessment_icon_white.svg'},

];
const MENUEMPLOYEE = [
{ state: 'employee/dashboard', name: 'Dashboard', iconUrl:"assets/images/Dashboard_icon.svg", type: 'link', iconUrlActive:'assets/images/Dashboard_icon_white.svg'},
{ state: 'employee/list-resource', name: 'Resources', iconUrl:"assets/images/Resources_icon.svg", type: 'link', iconUrlActive:'assets/images/Resources_icon_white.svg'},
{ state: 'employee/list-assessment', name: 'Assessment ', iconUrl:"assets/images/Assessment_icon.svg", type: 'link', iconUrlActive:'assets/images/Assessment_icon_white.svg'},
 ];

@Injectable()
export class MenuItems {
  userData
  constructor(private commonService:CommonService) {
    this.userData = this.commonService.getUser();
    this.getMenuitem();
    
  }
  getMenuitem(): Menu[] {

    
    if (this.userData.role =='admin') {
      return MENUITEMS;
    }
    if (this.userData.role =='employee') {
      return MENUEMPLOYEE;
    }
    if (this.userData.role =='manager') {
      return MENUMANAGER;
    }
   
  }

}
