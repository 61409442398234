import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StreamService {
  alertChannel: Subject<any> = new Subject();

  alertSubscription$ = this.alertChannel.asObservable();
  
  streamMessage(data: any) {
    let obj = {data:data}
    this.alertChannel.next(obj);
  }

  getSubscription(): Observable<any> {
    return this.alertSubscription$;
  }

  //for filter
  alertFilter :Subject<any> = new Subject();
  alertFilterSubscription$ = this.alertFilter.asObservable();
  streamFilterMessage(data: any) {
    let obj = data;
    this.alertFilter.next(obj);
  }

  getFilterSubscription(): Observable<any> {
    return this.alertFilterSubscription$;
  }
}
