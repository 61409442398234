<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->

<div class="position-relative">
  <div class="col-md text-center mt-1 mb-1">
    <img class="img" src="../../../../assets/img/vrenz_logo.png" />
    <span *ngIf="isMobileActive == true" (click)="closeSide()" class="cross">x</span>
    <!-- <img class="side_logo" src="../../../../assets/img/" alt="" /> -->
    <hr class="img_line" />
  </div>
  <mat-nav-list appAccordion>
    <div>
      <mat-list-item appAccordionLink *ngFor="let menuitem of currentNavition; index as i" group="{{ menuitem.state }}">
        <a
          (click)="activeRoute(i)"
          [ngClass]="{ selected: readLocalStorageValue('activeIndex') == i }"
          appAccordionToggle
          [routerLink]="[menuitem.state]"
          *ngIf="menuitem.type !== 'nolink'"
        >
          <span *ngIf="readLocalStorageValue('activeIndex') !== i"
            ><img class="logo_icon" [src]="menuitem.iconUrl"
          /></span>
          <span *ngIf="readLocalStorageValue('activeIndex') == i"
            ><img class="logo_icon" [src]="menuitem.iconUrlActive"
          /></span>
          <span class="menu_label" style="color: white !important" *ngIf="readLocalStorageValue('activeIndex') == i">{{
            menuitem.name
          }}</span>
          <span class="menu_label" *ngIf="readLocalStorageValue('activeIndex') !== i">{{ menuitem.name }}</span>
          <span fxFlex></span>
          <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>
      </mat-list-item>
    </div>

    <div></div>
  </mat-nav-list>
  <div class="logout">
    <hr class="img_line" />
    <span class="logout_bg" #out (click)="logOutPopUp(logOutModal, out)">
      Logout
      <img src="../../../../assets/img/logout.svg" class="logout_bg_img" alt="" />
    </span>
  </div>
</div>
<ng-template #logOutModal let-modal class="col-md-12">
  <div class="modal-body">
    <app-spinner *ngIf="isLoading1" [type]="'normal'"></app-spinner>
    <div class="row mt-3 col-md-12 justify-content-center m-0 p-0">
      <p class="_modal_text" style="font-size: 19px; color: black">Are you sure you want to <b>LogOut</b></p>
    </div>
    <div class="row justify-content-center m-0 p-0">
      <button class="w-50 btn addCandidateBtn" (click)="logOut()">Yes</button>
    </div>
    <div class="row mt-3 col-md-12 justify-content-center m-0 p-0">
      <button (click)="modal.dismiss('Cross click')" class="w-50 btn addCandidateBtn">No</button>
    </div>
  </div>
</ng-template>
