import { Component, OnInit } from "@angular/core";
import { CommonService } from "../../../shared/common.service";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["../full.component.scss"],
})
export class AppHeaderComponent implements OnInit {
  operatorCode: any;
  constructor(private commonService: CommonService, private router: Router) {}

  ngOnInit() {}
  logoutUser() {
    this.commonService.logOut();
  }

  navigateAPI() {
    this.router.navigate(["main/apikey/"]);
  }
}
