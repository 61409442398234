
import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatTabsModule } from "@angular/material/tabs";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MatNativeDateModule, MatOptionModule } from "@angular/material/core";
import { SpinnerComponent } from './spinner/spinner.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { SearchBtnComponent } from './search-btn/search-btn.component';
import { ResetBtnComponent } from './reset-btn/reset-btn.component';
import { AddBtnComponent } from './add-btn/add-btn.component';
import { AlertComponent } from './alert/alert.component';
import { AlertModule, AlertConfig } from 'ngx-bootstrap/alert';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CommonModule } from '@angular/common';
import { NoFile } from './no-file/no-file';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { UiSwitchModule } from 'ngx-toggle-switch';
import {DragDropModule} from '@angular/cdk/drag-drop';

import { NgbAlertModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatRadioModule} from '@angular/material/radio'
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatListModule} from '@angular/material/list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
// import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent,
    SearchBtnComponent,
    ResetBtnComponent,
    AddBtnComponent,
    AlertComponent,
   NoFile

  ],
  imports:[CommonModule,  MatSnackBarModule,FormsModule,AlertModule.forRoot(),NgMultiSelectDropDownModule.forRoot()],
  exports: [
    FormsModule, ReactiveFormsModule,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatOptionModule,
    MatIconModule,
    MatSelectModule,
    MatTabsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatRadioModule,
    MatMenuModule,
    MatSnackBarModule,
    MatTableModule,
    MatButtonModule,
    MatListModule,
    NgxDatatableModule,
    SpinnerComponent,
    SearchBtnComponent,
    ResetBtnComponent,
    AlertComponent,
    AlertModule,
    NgMultiSelectDropDownModule,
    ImageCropperModule,
    CommonModule,
    NoFile,MatProgressSpinnerModule,
    UiSwitchModule,
    NgbAlertModule,
    NgbPaginationModule,
    DragDropModule,
    MatCheckboxModule,
    NgxSliderModule,
    MatSlideToggleModule
   ],
  providers: [MenuItems,AlertConfig ]
})
export class SharedModule { }
