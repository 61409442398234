import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../../shared/shared.module';
import { NgOtpInputModule } from  'ng-otp-input';
import { LgFooterComponent } from '../../lg-footer/lg-footer.component';
import { AuthServiceLocal } from '../auth.service';
import { SigninComponent } from './signin/signin.component';
const router: Routes = [
  {path:"",component:LoginComponent,
children:[
  { path: '', redirectTo: 'signin', pathMatch: 'full' },
  {path:"signin",component:SigninComponent, }
]
  }
];

@NgModule({
  exports:[],
  imports: [CommonModule,SharedModule, RouterModule.forChild(router), FormsModule, ReactiveFormsModule, HttpClientModule,NgOtpInputModule],
  declarations: [LoginComponent,LgFooterComponent,  SigninComponent],
  providers:[AuthServiceLocal]
})
export class LoginModule { }
